import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <section className="why-per-person success-message">
      <div className="container">
        <img src="/images/icon-error.svg" alt="Error icon" />
        <h1>Something went wrong.</h1>

      </div>
    </section>
  </Layout>
)

export default NotFoundPage
